import { graphql } from 'gatsby';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { pageDataResolver } from '../utils/pageDataResolver';
import { Navbar } from '../components/navbar/navbar';
import RichText from '../components/rich-text/rich-text';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import { Footer } from '../components/footer/footer';
import { SeoComponent } from '../components/seo-component/seo-component';
import { PageProps } from 'gatsby';
import { CategoryModel } from '../models/category-model';
import { BlogCategoriesMenu } from '../components/blog/blog-categories-menu/blog-categories-menu';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import { filterById } from '../utils/utils';
import {
  AuthorWithArticleModel,
  IAuthorWithArticleProps
} from '../models/author-with-article-model';
import { authorsDataResolver } from '../utils/authorsDataResolver';
import isEmpty from 'lodash/isEmpty';
import '../styles/global.css';
import '../styles/reset.css';
import { withCookieBar } from '../hoc/withCookieBar';

interface IData {
  allKontentItemBlogArticle: {
    totalCount: number;
    nodes: IBlogArticle[];
  };
  kontentItemPage: IPage;
  allKontentItemBlogCategories: {
    nodes: ICategory[];
  };
  kontentItemBlogHero: IBlogHero;
  cookieSection: ICookieData;
}
interface IArticles {
  featured: IBlogArticle[];
  notFeatured: IBlogArticle[];
}

type BlogPageProps = PageProps<IData, IPageContext>;

const BlogPage: FunctionComponent<BlogPageProps> = props => {
  const { dispatch, state } = useContext(AppStore);

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });

    const debouncedResize = debounce(handleResize, 300);

    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          prevUrl: props.location.state ? props.location.state.prevUrl : '',
          currentUrl: props.pageContext.pageUrl || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();

    handleChangePageState();
    handleResize();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    props.data.cookieSection.elements.button_cta.value,
    props.data.cookieSection.elements.text.value,
    props.location.state,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    state.showActiveDevice
  ]);

  const filteredArticles = useMemo(
    () => filterById(props.data.allKontentItemBlogArticle.nodes),
    [props.data.allKontentItemBlogArticle.nodes]
  );
  const sortedArticles = useMemo(
    () => (): IArticles => {
      return filteredArticles.reduce(
        (articles: IArticles, article: IBlogArticle) => {
          const featured =
            get(article, 'elements.featured.value[0].codename', false) ===
            'true';

          if (featured) {
            articles.featured.push(article);
            return articles;
          }

          articles.notFeatured.push(article);
          return articles;
        },
        {
          featured: [],
          notFeatured: []
        }
      );
    },
    [filteredArticles]
  );

  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);
  const filteredCategories = useMemo(
    () =>
      props.data.allKontentItemBlogCategories.nodes &&
      filterById(props.data.allKontentItemBlogCategories.nodes),
    [props.data.allKontentItemBlogCategories.nodes]
  );
  const blogCategories = useMemo(
    () =>
      filteredCategories.map((category: ICategory, i: number) =>
        CategoryModel.create(category)
      ),
    [filteredCategories]
  );
  const articleWithAuthorData = useMemo(
    () =>
      filteredArticles.map(
        (article: IBlogArticle): IAuthorWithArticleProps => {
          return AuthorWithArticleModel.create(article);
        }
      ),
    [filteredArticles]
  );
  const authorsData = useMemo(
    () => authorsDataResolver(articleWithAuthorData),
    [articleWithAuthorData]
  );

  return (
    <>
      <SeoComponent {...pageData.seoData} />
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      <BlogCategoriesMenu
        currentUrl={props.location.pathname}
        categories={blogCategories}
      />
      <RichText
        {...props.data.kontentItemPage.elements.body}
        articles={sortedArticles()}
        authors={authorsData}
      />
      {!isEmpty(pageData.footerData) && <Footer {...pageData.footerData} />}
    </>
  );
};

export default withAppStore(withCookieBar(BlogPage as FunctionComponent<{}>));

export const query = graphql`
  query($pageId: String, $pageLanguage: String, $category: [String]) {
    allKontentItemBlogArticle(
      filter: {
        system: { language: { eq: $pageLanguage } }
        elements: {
          blog_category: {
            value: { elemMatch: { system: { codename: { in: $category } } } }
          }
        }
      }
      sort: { fields: elements___publication_date___value, order: DESC }
    ) {
      totalCount
      nodes {
        ...FragmentBlogArticleListItem
      }
    }
    allKontentItemBlogCategories(
      filter: { system: { language: { eq: $pageLanguage } } }
    ) {
      nodes {
        ...FragmentBlogCategory
      }
    }
    kontentItemPage(
      system: { id: { eq: $pageId }, language: { eq: $pageLanguage } }
    ) {
      ...FragmentPageComponent
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
  }
`;
