import { ContentItem } from '@kentico/kontent-delivery';
import get from 'lodash/get';

export interface IAuthorWithArticleProps {
  author: IAuthorBoxData;
  article: IArticleData;
}

export interface IArticleData {
  title: string;
  slug: string;
  category: string;
}

export interface IAuthorBoxData {
  id: string;
  firstName: string;
  lastName: string;
  position: string;
  photoUrl: string;
  slug: string;
}

export class AuthorWithArticleModel extends ContentItem {
  get author(): IAuthorBoxData {
    const id = get(this._config, 'elements.author.value[0].id', '');
    const firstName = get(
      this._config,
      'elements.author.value[0].elements.first_name.value',
      ''
    );
    const lastName = get(
      this._config,
      'elements.author.value[0].elements.last_name.value',
      ''
    );
    const position = get(
      this._config,
      'elements.author.value[0].elements.position.value',
      ''
    );
    const photoUrl = get(
      this._config,
      'elements.author.value[0].elements.photo.value[0].url',
      ''
    );
    const slug = get(
      this._config,
      'elements.author.value[0].system.codename',
      ''
    );

    return {
      id,
      firstName,
      lastName,
      position,
      photoUrl,
      slug
    };
  }

  get article(): {
    title: string;
    slug: string;
  } {
    const title = get(this._config, 'elements.title_value.value', '');
    const category = get(
      this._config,
      'elements.blog_category.value[0].elements.category_url_value.value',
      ''
    );
    const slug = get(
      this._config,
      'elements.basic_page_settings__url.value',
      ''
    );

    return {
      title,
      slug,
      category
    };
  }

  public static create(data: IBlogArticle): IAuthorWithArticleProps {
    const { author, article } = new AuthorWithArticleModel(data);
    return {
      author,
      article
    };
  }
}
